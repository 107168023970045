angular
  .module('workflow')

  .controller('WorkflowPreInvoicedController', [
    '$filter',
    '$scope',
    '$state',
    '$timeout',
    'Confirm',
    'Dialog',
    'WorkflowStore',
    'WorkflowUnlinkAsParentCrud',

    function(
      $filter,
      $scope,
      $state,
      $timeout,
      Confirm,
      Dialog,
      WorkflowStore,
      WorkflowUnlinkAsParentCrud
    ) {
      $scope.linkToParent = () => {
        const unregister = $scope.$on('save:done', () => {
          unregister();
          $state.go('^.^.orders', {
            linkingToParent: true,
            fromWorkflowId: $state.params.id
          });
        });
        WorkflowStore.emit('save', $scope.workflow);
      };

      $scope.goTo = workflow => {
        if (!workflow || !workflow._id) return;

        $state.go('^.^.order.projects.list', {
          id: workflow._id
        });
      };

      $scope.unlinkAsParent = childOrder => {
        if (!childOrder || !childOrder._id) return;

        const unlink = () => {
          new WorkflowUnlinkAsParentCrud($state.params.id)
            .update({ childWorkflowId: childOrder._id })
            .then(() => {
              $scope.workflow.childrenOrders = Array.isArray(
                $scope.workflow.childrenOrders
              )
                ? $scope.workflow.childrenOrders
                : [];
              $scope.workflow.childrenOrders = $scope.workflow.childrenOrders.filter(
                item => item && item._id !== childOrder._id
              );
              $timeout(
                () => new Dialog().success($filter('translate')('crud.SAVED')),
                250
              );
            })
            .catch(e => {
              console.error(e);
              new Dialog().error($filter('translate')('error.RECEIVE_ERR'));
            });
        };

        new Confirm()
          .show($filter('translate')('order.UNLINK_FROM_PARENT_MSG'))
          .then(unlink);
      };

      $scope.$watch('workflow.childrenOrders', childrenOrders => {
        $scope.totalPreInvoiced = (childrenOrders || []).reduce(
          (acc, order) =>
            acc + ((order && order.totals && order.totals.amount) || 0) || acc,
          0
        );
      });

      $scope.$watchGroup(
        ['workflow.currency', 'workflow.exchangeRate'],
        ([currency, exchangeRate]) => {
          $scope.hasForeignCurrency = !!(
            currency &&
            currency !== 'EUR' &&
            exchangeRate
          );
        }
      );

      $scope.$watchGroup(
        [
          'hasForeignCurrency',
          'workflow.childrenOrdersDiscount',
          'workflow.exchangeRate'
        ],
        ([hasForeignCurrency, childrenOrdersDiscount, exchangeRate]) => {
          const discount = childrenOrdersDiscount || 0;
          $scope.discountEur = hasForeignCurrency
            ? discount / (exchangeRate || 1)
            : discount;
          $scope.discountForeignCurrency = hasForeignCurrency ? discount : 0;
        }
      );
    }
  ]);
